<template>
  <div>
    <IconButton
      icon="mdi-content-copy"
      bgcolor="transparent"
      @clicked="showPopup"
      :color="color"
      noYMargin
      noXMargin
    ></IconButton>
    <DuplicationPopup
      v-if="show"
      :value.sync="show"
      :subtitle="name"
      :model="model"
      :text="value"
      :patient="patient"
      :session="session"
      :duplication.sync="internalDuplication"
      @success="x => handleSuccess(x)"
      @error="x => handleError(x)"
    ></DuplicationPopup>
  </div>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    name: {},
    model: {},
    value: {},
    patient: {},
    session: {},
    duplication: {}
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    color() {
      return this.internalDuplication !== "" ? "primary" : "icons";
    },
    internalDuplication: {
      get() {
        return this.duplication;
      },
      set(value) {
        this.$emit("update:duplication", value);
      }
    }
  },
  components: {
    IconButton: () => import("@/components/buttons/IconButton"),
    DuplicationPopup: () => import("@/components/popups/DuplicationPopup")
  },
  methods: {
    showPopup() {
      this.show = true;
    }
  }
};
</script>